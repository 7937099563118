import React from 'react';

function Footer() {
    return (
        <footer>
            <div className="footer-container">
                <div className="product-name-container">
                    <span className="smallish header-footer-text">Atlassian Quote Accelerator (AQuA)</span>
                </div>
                <div className="version-container">
                    <span className="smallish header-footer-text">(v0.8.9)</span>
                </div>

                <div className="help-container">
                    <a href="https://e7solutions.atlassian.net/browse/AQUA" target="_new"
                       className="smallish header-footer-text">Help | Submit a Ticket</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;